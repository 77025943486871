import React, { useEffect, useRef } from 'react';
import './styles.css';

const Chatbot = () => {
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.voiceflow.com/widget/bundle.mjs';

    script.onload = function() {
      if (window.voiceflow && window.voiceflow.chat) {
        window.voiceflow.chat.load({
          verify: { projectID: '667becda461c4d81807fe278' },
          url: 'https://general-runtime.voiceflow.com',
          versionID: 'production',
          assistant: {
            description: `Chats are recorded for training and quality purposes, and any personal information you provide will be handled in accordance with our <a href="https://www.myuif.com/privacy-policy-legal/" target="_blank">Privacy Notification</a>. Please don't share sensitive information such as your social security number, account PINs, login information, or passwords.`,
            stylesheet: "https://firebasestorage.googleapis.com/v0/b/themc-d23c7.appspot.com/o/custom-styles.css?alt=media&token=0387174c-a5f4-4043-9c81-07a3f26f6228",
          },
        }).then(() => {
          observeChatBubbleAndIcon();
          observeDescription();
        });
      }
    };

    document.body.appendChild(script);
  }, []);

  const observeChatBubbleAndIcon = () => {
    const voiceflowChat = document.getElementById('voiceflow-chat');
    if (voiceflowChat) {
      const shadowRoot = voiceflowChat.shadowRoot;
      if (shadowRoot) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {

            // Add a CTA bubble
            const buttonElement = shadowRoot.querySelector('.vfrc-launcher.c-bQoszf');
            if (buttonElement) {
              console.log('Launcher button found:', buttonElement);

              const bubbleDiv = document.createElement('div');
              bubbleDiv.className = 'chat-bubble';

              bubbleDiv.innerHTML = `
                <div class="bubble-header">
                  UIF Virtual Assistant <span class="close-btn">&times;</span>
                </div>
                <div class="bubble-body">
                  Salaam, I am here to help if you have any questions.
                </div>
              `;

              document.body.appendChild(bubbleDiv);

              const rect = buttonElement.getBoundingClientRect();
              bubbleDiv.style.bottom = `${window.innerHeight - rect.bottom + 60}px`;
              bubbleDiv.style.right = `${window.innerWidth - rect.right + 10}px`;

              bubbleDiv.querySelector('.close-btn').addEventListener('click', () => {
                bubbleDiv.remove();
              });

              setTimeout(() => {
                bubbleDiv.remove();
              }, 5000);

            } else {
              console.warn("Element for launcher button not found.");
            }
          });
        });

        observer.observe(shadowRoot, {
          childList: true,
          subtree: true,
        });
      } else {
        console.warn("Shadow root not accessible.");
      }
    } else {
      console.warn("Voiceflow chat container not found.");
    }
  };

  const observeDescription = () => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const voiceflowChat = document.getElementById('voiceflow-chat');
        if (voiceflowChat) {
          const shadowRoot = voiceflowChat.shadowRoot;
          if (shadowRoot) {
            // Change the chat assistant description to include the link
            const element = shadowRoot.querySelector('.vfrc-assistant-info--description.c-eLmjZC');
            if (element) {
              element.innerHTML = `Chats are recorded for training and quality purposes, and any personal information you provide will be handled in accordance with our <a href="https://www.myuif.com/privacy-policy-legal/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. Please don't share sensitive information such as your social security number, account PINs, login information, or passwords.`;
              observer.disconnect();  // Stop observing once the description is updated
            } else {
              console.warn("Element for assistant description not found.");
            }
          } else {
            console.warn("Shadow root not accessible for description.");
          }
        } else {
          console.warn("Voiceflow chat container not found.");
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  };

  return (
    <div className="chatbot-container" ref={chatContainerRef}>
      {}
    </div>
  );
};

export default Chatbot;
