import React, { useState, useRef } from 'react';

export const Contact1 = (props) => {
  const form = useRef();
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [statusMessage, setStatusMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState(prevState => ({ ...prevState, [name]: value }));
  };

  const isEmailValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formState.name || !formState.email || !formState.message) {
      setStatusMessage('Please fill in all fields.');
      return;
    }

    if (!isEmailValid(formState.email)) {
      setStatusMessage('Please enter a valid email address.');
      return;
    }

    setIsLoading(true);
    fetch('https://us-central1-blackbyrd-404301.cloudfunctions.net/app/send-email', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(formState),
  })
  .then(response => response.json())
  .then(data => {
      console.log(data.message);
      setStatusMessage('Message sent successfully!');
      setFormState({ name: '', email: '', message: '' });
      setIsLoading(false);
  })
  .catch(error => {
      console.error('Error:', error);
      setStatusMessage('Failed to send message, please try again.');
      setIsLoading(false);
  });
  };

  return (
    <div id="contact">
      <div className="container">
        <div className="row align-items-start">
          <div className="col-md-9">
            <div className="section-title">
              <h2>Get In Touch</h2>
              <p>Please fill out the form below to send us an email and we will get back to you as soon as possible.</p>
            </div>
            <form ref={form} onSubmit={handleSubmit} noValidate>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                      required
                      onChange={handleChange}
                      value={formState.name} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      required
                      onChange={handleChange}
                      value={formState.email} />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  rows="4"
                  placeholder="Message"
                  required
                  onChange={handleChange}
                  value={formState.message}>
                </textarea>
              </div>
              <div id="success">
                <p className="help-block text-danger">{statusMessage}</p>
              </div>
              <button type="submit" className="btn btn-custom btn-lg" disabled={isLoading}>
                {isLoading ? 'Sending...' : 'Send Message'}
              </button>
            </form>
          </div>
          <div className="col-md-3">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span><i className="fa fa-map-marker"></i> Address</span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span><i className="fa fa-phone"></i> Phone</span>{" "}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span><i className="fa fa-envelope-o"></i> Email</span>{" "}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2023 Blackbyrd AI LLC</p>
        </div>
      </div>
    </div>
  );
};